@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  max-width: 100%;
}

.logo {
  max-width: 100%;
  margin: 0;
}

.helloText {
  font-weight: 300;
  margin: 6px 0 46px 0;
  font-size: 22px;
  letter-spacing: 0.5px;

  @include breakpoint(1024px) {
    font-size: 18px;
  }
}

.loginBtn {
  // TODO: reuse these btn  styles across HDD
  background: #5626f7;
  color: white;
  border: none;
  box-shadow: none;
  outline: none;
  gap: 8px;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;

  &:hover {
    background: #3f17c3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #9e9ef2;
  }
}
